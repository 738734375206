// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProcessingLoadingText {
    width: 200px;
}

.ProcessingLoadingText-word {
    position: absolute;
    width: 200px;
    text-align: left;
    font-weight: 600;
}

.ProcessingLoadingText-current {
    animation: 2000ms words-current ease-in-out infinite;
}

.ProcessingLoadingText-next {
    animation: 2000ms words-next ease-in-out infinite;
}

@keyframes words-current {
    0% {
        opacity: 100%;
        top: 0px;
    }

    20% {
        opacity: 0%;
        top: -20px;
    }

    100% {
        opacity: 0%;
        top: -20px;
    }
}

@keyframes words-next {
    0% {
        opacity: 0%;
        top: 20px;
    }

    20% {
        opacity: 100%;
        top: 0px;
    }

    100% {
        opacity: 100%;
        top: 0px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/ProcessingLoadingText/ProcessingLoadingText.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,oDAAoD;AACxD;;AAEA;IACI,iDAAiD;AACrD;;AAEA;IACI;QACI,aAAa;QACb,QAAQ;IACZ;;IAEA;QACI,WAAW;QACX,UAAU;IACd;;IAEA;QACI,WAAW;QACX,UAAU;IACd;AACJ;;AAEA;IACI;QACI,WAAW;QACX,SAAS;IACb;;IAEA;QACI,aAAa;QACb,QAAQ;IACZ;;IAEA;QACI,aAAa;QACb,QAAQ;IACZ;AACJ","sourcesContent":[".ProcessingLoadingText {\n    width: 200px;\n}\n\n.ProcessingLoadingText-word {\n    position: absolute;\n    width: 200px;\n    text-align: left;\n    font-weight: 600;\n}\n\n.ProcessingLoadingText-current {\n    animation: 2000ms words-current ease-in-out infinite;\n}\n\n.ProcessingLoadingText-next {\n    animation: 2000ms words-next ease-in-out infinite;\n}\n\n@keyframes words-current {\n    0% {\n        opacity: 100%;\n        top: 0px;\n    }\n\n    20% {\n        opacity: 0%;\n        top: -20px;\n    }\n\n    100% {\n        opacity: 0%;\n        top: -20px;\n    }\n}\n\n@keyframes words-next {\n    0% {\n        opacity: 0%;\n        top: 20px;\n    }\n\n    20% {\n        opacity: 100%;\n        top: 0px;\n    }\n\n    100% {\n        opacity: 100%;\n        top: 0px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
