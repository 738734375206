// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Upload-modal-container {
    width: 30%;
    min-width: 500px;
    padding: 24px;
    border: 1px solid black;
    border-radius: 4px;
    margin: 32px 0;
    gap: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/UploadModal/UploadModal.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,cAAc;IACd,SAAS;AACb","sourcesContent":[".Upload-modal-container {\n    width: 30%;\n    min-width: 500px;\n    padding: 24px;\n    border: 1px solid black;\n    border-radius: 4px;\n    margin: 32px 0;\n    gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
